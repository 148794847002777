$sylius-brand-color: #1abb9c !default;
$segment-spacing: 1.8em !default;
$segment-title-spacing: 1.2em 1.8em !default;
$accordion-title-spacing: 1.2em 1.8em !default;

// ----------------------------------
// ------------ Globals
// ----------------------------------

.ui.inverted.menu {
    background: #1d212b;
}

body.pushable .pusher {
    background: #f9f9f9;
}

#wrapper.full.height {
    padding: 64px 12px 0 12px;
}

a {
    color: $sylius-brand-color;
    &:hover { color: $sylius-brand-color; }
}

// ----------------------------------
// ------------ Buttons
// ----------------------------------

.ui.button {
    font-weight: 400;
}

.ui.primary.button {
    background-color: $sylius-brand-color;
    &:hover, &:focus { background-color: #279c7f; }
}

.ui.labeled.icon.button {
    padding-left: 2.8em !important;
}

.ui.labeled.icon.button>.icon,
.ui.labeled.icon.buttons>.button>.icon {
    width: 2.8em !important;
    background: none !important;
    padding-left: .2em;
}

.ui-element-spacer {
    margin-bottom: 1em;
}

// ----------------------------------
// ------------ Forms
// ----------------------------------

.ui.form .field > label {
    margin: 0 0 .5rem 0;
    color: rgba(0,0,0,.8);
    font-weight: 400;
}

.ui.form .field > input:focus,
.ui.form .field > textarea:focus {
    border-color: rgba($sylius-brand-color, .4);
}

// ----------------------------------
// ------------ Sidebar menu
// ----------------------------------

.admin-layout__nav,
#sidebar.ui.sidebar.vertical.menu {
    > .item:first-child {
        margin-bottom: 10px;
    }

    > .item:not(:first-child) {
        margin-right: 10px;
    }

    .item > .header {
        text-transform: uppercase;
        font-size: 11px;
        margin-bottom: 16px;
    }

    .item > i.icon {
        float: none !important;
        margin: 2px 10px 2px 0 !important;
        opacity: .5;
    }

    .item::before {
        display: none;
    }

    .item.active {
        font-weight: inherit !important;
        background: $sylius-brand-color !important;
        border-radius: 0 99px 99px 0 !important;
    }
}

// ----------------------------------
// ------------ Header
// ----------------------------------

.ui.header {
    .content {
        padding-left: 1.3rem !important;
    }

    .content .sub.header {
        font-size: 1rem;
        color: #9a9a9a;
        padding-top: 3px;
    }

    .circular.icon {
        width: 2.5em !important;
        height: 2.5em !important;
        font-size: 1em;
        color: $sylius-brand-color;
        background: rgba($sylius-brand-color, .1);
        box-shadow: none;
    }
}

.ui.action.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

// ----------------------------------
// ------------ Breadcrumb
// ----------------------------------

.ui.breadcrumb {
    .active {
        color: #9a9a9a;
        font-weight: 400 !important;
    }

    a {
        color: #9a9a9a;
        &:hover { color: $sylius-brand-color; }
    }

    .divider {
        opacity: .4;
    }
}

// ----------------------------------
// ------------ Segment
// ----------------------------------

.ui.segment,
.ui.attached.segment {
    box-shadow: 0 2px 4px 0 rgba(34,36,38,.03);
    border: 1px solid rgba(34,36,38,.1);
    padding: $segment-spacing;
    width: 100%;
}

.ui.attached.segment {
    margin-left: 0;
    margin-right: 0;
}

.ui.attached.header {
    padding: $segment-title-spacing;
    margin: 0;
    box-shadow: 0 2px 4px 0 rgba(34,36,38,.03);
    border: 1px solid rgba(34,36,38,.1);
    border-bottom: 1px solid #eaeaea;
    font-size: inherit;
}

.segment.spaceless {
    padding: 0 !important;
}

// ----------------------------------
// ------------ Accordion
// ----------------------------------

.ui.styled.accordion {
    box-shadow: 0 2px 4px 0 rgba(34,36,38,.03);
    border: 1px solid rgba(34,36,38,.1);

    .title {
        padding: $accordion-title-spacing;
        border-top: none;
    }

    .content {
        padding: $segment-spacing;
        border-top: 1px solid #eaeaea;
    }
}

// ----------------------------------
// ------------ Pagination / Menu
// ----------------------------------

.ui.menu {
    box-shadow: 0 2px 4px 0 rgba(34,36,38,.03);
    border: 1px solid rgba(34,36,38,.1);
}

// ----------------------------------
// ------------ Table
// ----------------------------------

.sylius-grid-table-wrapper {
    .ui.sortable.table {
        thead th {
            padding-top: 20px;
            padding-bottom: 20px;

            &.sorted {
                background: inherit;
                color: $sylius-brand-color;
            }
        }
    }
}

.sylius-grid-image {
    max-height: 50px;
    max-width: 50px;
}

// ----------------------------------
// ------------ Toggle
// ----------------------------------

.ui.toggle.checkbox input:checked~.box:before, .ui.toggle.checkbox input:checked~label:before,
.ui.toggle.checkbox input:focus:checked~.box:before, .ui.toggle.checkbox input:focus:checked~label:before {
    background-color: $sylius-brand-color !important;
}

// ----------------------------------
// ------------ Tabs
// ----------------------------------

.ui.top.attached.tabular.menu {
    flex-wrap: wrap;
}

// ----------------------------------
// ------------ Statistics
// ----------------------------------

.stats {
    .stats-grid {
        display: flex;
        flex-wrap: nowrap;

        @media (max-width: 1000px) {
            flex-wrap: wrap;
        }
    }

    .stats-column {
        flex-grow: 1;
        flex-shrink: 0;
        width: 25%;
        padding: 20px;
        text-align: center;
        box-shadow: 0 -1px 0 0 #ebebeb, 1px 0 0 0 #ebebeb;

        @media (max-width: 1000px) {
            width: 50%;
        }

        @media (max-width: 600px) {
            width: 100%;
        }
    }

    .stats-graph {
        height: 400px;
    }

    .stats-loader {
        background: rgba(249, 249, 249, 0.7) !important;
    }

    .ui.basic.active.button {
        color: $sylius-brand-color !important;
    }
}

// ----------------------------------
// ------ Catalog Promotions
// ----------------------------------

div[id^="sylius_catalog_promotion_actions"] {
    margin-bottom: 10px;
}

// ----------------------------------
// ------------ Navigation
// ----------------------------------

.navigation {
    position: absolute;
    top: 5px;
    bottom: 5px;
    margin: 5px;
    width: 40px;
    border: none;
    display: flex;
    justify-content: center;
    flex-direction: column;
    font-size: 40px;
    color: grey;
    background-color: transparent;
    text-align: center;
    align-items: center;
    cursor: pointer;

    &:hover{
        color: black;
    }

    i {
        pointer-events: none;
    }

    &:focus {
        outline: 0;
    }
}

.navigation-prev {
    left: 0;
}

.navigation-next {
    right: 0;
}

// ----------------------------------
// ------------ Sticky columns
// ----------------------------------

@media (min-width: 1000px) {
    .sticky-container {
        align-items: flex-start !important;
    }

    .sticky-column {
        position: sticky !important;
        top: 60px;
    }
}

// ----------------------------------
// ------------ Order Notes Info
// ----------------------------------

.text-break {
    word-break: break-word;
}
