@import "attributes";
@import "layout";
@import "taxonomy";
@import "ui";

.tax-disabled {
    color: #a0a0a0;
}

.order-summary-table .sylius-table-column-tax,
.order-summary-table .sylius-table-column-total {
    width: 90px;
}

.text.gray {
    color: #999;
}

.ui.buttons form .button {
    border-radius: 0 !important;
}

.ui.buttons form:first-child .button {
    border-top-left-radius: .28571429rem !important;
    border-bottom-left-radius: .28571429rem !important;
}

.ui.buttons form:last-child .button {
    border-top-right-radius: .28571429rem !important;
    border-bottom-right-radius: .28571429rem !important;
}

.channel {
    display: inline-flex;
    align-items: baseline;
    flex-wrap: nowrap;

    .channel__item {
        white-space: nowrap;
    }

    .channel__item.label {
        transform: translateY(1px);
    }

    .channel__item:not(:last-child) {
        margin-right: 8px;
    }
}

.promotion-disabled {
    color: #a0a0a0;
}
